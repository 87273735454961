import dotenv from 'dotenv'
import Stripe from 'stripe'

dotenv.config({ path: '.env.local' })
export enum StripeEnvKeys {
	STRIPE_ADMIN_KEY = 'STRIPE_ADMIN_KEY',
	STRIPE_ADMIN_PROD_KEY = 'STRIPE_ADMIN_PROD_KEY',
}

// only for branches: vercel and main - never allow Stripe TEST to creep into production
export const IS_PROD_ENV = process.env.NEXT_PUBLIC_IS_PROD === 'true' || process.env.NEXT_PUBLIC_IS_STAGING === 'true' || false
export const IS_AFFIRM_SANDBOX = !IS_PROD_ENV

export const STRIPE_KEY = IS_PROD_ENV ? StripeEnvKeys.STRIPE_ADMIN_PROD_KEY : StripeEnvKeys.STRIPE_ADMIN_KEY
export const STRIPE_ADMIN_KEY = IS_PROD_ENV ? process.env.STRIPE_ADMIN_PROD_KEY : process.env.STRIPE_ADMIN_KEY

export const STRIPE_CLIENT_KEY =
	STRIPE_KEY === StripeEnvKeys.STRIPE_ADMIN_PROD_KEY
		? 'pk_live_51L893zDptqM2bn1xNXytaS2qEQL35k7d7ZaQ0GOM32LFZrFh7ztqI9YLUlMcC3TIj3jzgPtzU6UDcGXdLnOuTdV200V5P13R2q'
		: 'pk_test_51L893zDptqM2bn1xnKHngLDdoGw31lP180vXOJUziIF5JrIhjgBWvYxotYSIKtv3XYF9ry1fqp8fcKk5MOLvp3Kk00i1QBI3Qa'

export const TAX_PRODUCT = STRIPE_KEY === StripeEnvKeys.STRIPE_ADMIN_PROD_KEY ? 'prod_OvRnehcGgIjriC' : 'prod_OupNhkYfFDtkY4'

export const STRIPE_FREE_SHIPPING = STRIPE_KEY === StripeEnvKeys.STRIPE_ADMIN_PROD_KEY ? 'shr_1QoW8mDptqM2bn1xom0ere4q' : 'shr_1QoWJyDptqM2bn1xFsIYLOgM'

export const AVALARA_CREDENTIALS =
	STRIPE_KEY === StripeEnvKeys.STRIPE_ADMIN_PROD_KEY
		? {
				username: 'alicia@eightsleep.com',
				password: '8Accounting2023!',
		  }
		: {
				username: 'nick.xitco@eightsleep.com',
				password: '9nwrCfuk$xc!mKVb7@g5yNglUFYTY#ixA',
		  }

export const AVALARA_COMPANY_CODE = STRIPE_KEY === StripeEnvKeys.STRIPE_ADMIN_PROD_KEY ? 'EIGHTSLEEP' : 'DEFAULT'

export type CouponMetadataType = 'line_item' | 'stacking' | 'global' | 'system' | 'single_item'

export type ProductCollection = 'cover' | 'mattress' | 'accessories' | 'upgrade' | 'ultra' | 'cover,mattress' | 'cover,ultra' | 'cover,mattress,subscription' | 'base' | 'subscription' | 'supplements'

export interface CouponMetadata {
	type: CouponMetadataType
	source: 'script' | 'verifypass'
	collections: ProductCollection
	discount_map?: string
	is_global_sale?: true
	max_redemptions?: number
	redeem_by?: number
}

export const sanitizeMetadata = (metadata: CouponMetadata): Stripe.MetadataParam => {
	const sanitized: Stripe.MetadataParam = {}

	Object.entries(metadata).forEach(([key, value]) => {
		if (value !== undefined) {
			sanitized[key] = value as string
		}
	})

	return sanitized
}

const getStripeManager = (): { stripe: Stripe } => {
	// TODO: I was trying to add an error here if the key is not set, but it was causing issues with the build,
	// likely because some client side code is (wrongly) importing this file.
	return {
		stripe: new Stripe(STRIPE_ADMIN_KEY ?? '', { apiVersion: '2024-06-20' }),
	}
}

export const StripeManager = getStripeManager()

if (process.env.NEXT_PUBLIC_SANDBOX_AFFIRM_CA_PUBLIC_KEY === undefined) throw new Error('NEXT_PUBLIC_SANDBOX_AFFIRM_CA_PUBLIC_KEY is not set')
if (process.env.NEXT_PUBLIC_AFFIRM_CA_PUBLIC_KEY === undefined) throw new Error('NEXT_PUBLIC_AFFIRM_CA_PUBLIC_KEY is not set')
if (process.env.NEXT_PUBLIC_SANDBOX_AFFIRM_CA_URL === undefined) throw new Error('NEXT_PUBLIC_AFFIRM_US_PUBLIC_KEY is not set')
if (process.env.NEXT_PUBLIC_AFFIRM_CA_URL === undefined) throw new Error('NEXT_PUBLIC_AFFIRM_CA_URL is not set')
if (process.env.NEXT_PUBLIC_SANDBOX_AFFIRM_CA_JS === undefined) throw new Error('NEXT_PUBLIC_AFFIRM_CA_JS is not set')
if (process.env.NEXT_PUBLIC_AFFIRM_CA_JS === undefined) throw new Error('NEXT_PUBLIC_AFFIRM_CA_JS is not set')
if (typeof window === 'undefined' && process.env.SANBOX_AFFIRM_CA_PASSWORD === undefined) throw new Error('SANBOX_AFFIRM_CA_PASSWORD is not set')
if (typeof window === 'undefined' && process.env.AFFIRM_CA_PASSWORD === undefined) throw new Error('AFFIRM_CA_PASSWORD is not set')

export const AFFIRM_CA_PUBLIC_KEY = IS_AFFIRM_SANDBOX ? process.env.NEXT_PUBLIC_SANDBOX_AFFIRM_CA_PUBLIC_KEY : process.env.NEXT_PUBLIC_AFFIRM_CA_PUBLIC_KEY
export const AFFIRM_CA_URL = IS_AFFIRM_SANDBOX ? process.env.NEXT_PUBLIC_SANDBOX_AFFIRM_CA_URL : process.env.NEXT_PUBLIC_AFFIRM_CA_URL
export const AFFIRM_CA_JS = IS_AFFIRM_SANDBOX ? process.env.NEXT_PUBLIC_SANDBOX_AFFIRM_CA_JS : process.env.NEXT_PUBLIC_AFFIRM_CA_JS
export const AFFIRM_CA_PASSWORD = IS_AFFIRM_SANDBOX ? process.env.SANBOX_AFFIRM_CA_PASSWORD : process.env.AFFIRM_CA_PASSWORD

if (process.env.NEXT_PUBLIC_SANDBOX_AFFIRM_US_PUBLIC_KEY === undefined) throw new Error('NEXT_PUBLIC_SANDBOX_AFFIRM_US_PUBLIC_KEY is not set')
if (process.env.NEXT_PUBLIC_AFFIRM_US_PUBLIC_KEY === undefined) throw new Error('NEXT_PUBLIC_AFFIRM_US_PUBLIC_KEY is not set')
if (process.env.NEXT_PUBLIC_SANDBOX_AFFIRM_US_URL === undefined) throw new Error('NEXT_PUBLIC_AFFIRM_US_PUBLIC_KEY is not set')
if (process.env.NEXT_PUBLIC_AFFIRM_US_URL === undefined) throw new Error('NEXT_PUBLIC_AFFIRM_US_URL is not set')
if (process.env.NEXT_PUBLIC_SANDBOX_AFFIRM_US_JS === undefined) throw new Error('NEXT_PUBLIC_AFFIRM_US_JS is not set')
if (process.env.NEXT_PUBLIC_AFFIRM_US_JS === undefined) throw new Error('NEXT_PUBLIC_AFFIRM_US_JS is not set')

export const AFFIRM_US_PUBLIC_KEY = IS_AFFIRM_SANDBOX ? process.env.NEXT_PUBLIC_SANDBOX_AFFIRM_US_PUBLIC_KEY : process.env.NEXT_PUBLIC_AFFIRM_US_PUBLIC_KEY
export const AFFIRM_US_URL = IS_AFFIRM_SANDBOX ? process.env.NEXT_PUBLIC_SANDBOX_AFFIRM_US_URL : process.env.NEXT_PUBLIC_AFFIRM_US_URL
export const AFFIRM_US_JS = IS_AFFIRM_SANDBOX ? process.env.NEXT_PUBLIC_SANDBOX_AFFIRM_US_JS : process.env.NEXT_PUBLIC_AFFIRM_US_JS
